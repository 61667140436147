
import { computed, defineComponent, PropType, reactive, watch } from 'vue'
import { User, validateUser } from '@/modules/techdemo/model/users.model'
import { errored, loaded, isLoaded, LOADING_STATE, LoadingState, isLoading, isErrored } from '@/utils/loading-state'
import { getAccessMetadata } from '@/modules/techdemo/api/metadata.api'
import AccessEditor from '@/modules/techdemo/components/AccessEditor.vue'
import { AccessMetadata, extendAccessMetadataWithUserAccessData } from '@/modules/techdemo/model/access.model'
import { AccessTemplate } from '@/modules/techdemo/model/access-template.model'
import { getAllAccessTemplates } from '@/modules/techdemo/api/access-templates.api'
import {
  FORM_INITIAL_STATE,
  FORM_LOADING_STATE,
  formError,
  FormState,
  formSuccess,
  isFormErrored,
  isFormLoading,
  isFormSuccess,
} from '@/utils/form-state'
import { createUser, deleteUser, updateUser } from '@/modules/techdemo/api/users.api'
import FormStateAlert from '@/components/UI/FormStateAlert.vue'
import { useRouter } from 'vue-router'
import PanelSubheading from '@/components/UI/PanelSubheading.vue'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { TechdemoRoute } from '@/modules/techdemo/routes'
import HiddenAccessItemsNotice from '@/components/UI/HiddenAccessItemsNotice.vue'
import { ApiError } from '@/api/_shared'
import { useToaster } from '@/composables/useToaster'

const CUSTOM_ACCESS_OPTION = 'No template (custom access)' as const

export default defineComponent({
  name: 'UserEditor',
  components: {
    HiddenAccessItemsNotice,
    DataLoadingError,
    DataLoader,
    PanelSubheading,
    FormStateAlert,
    AccessEditor,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isNewUser: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:user'],
  setup(props, { emit }) {
    const data = reactive({
      formState: FORM_INITIAL_STATE as FormState,
      accessMetadata: LOADING_STATE as LoadingState<AccessMetadata>,
      accessTemplates: LOADING_STATE as LoadingState<AccessTemplate[]>,
      showDeleteModal: false,
    })

    const localUser = computed({
      get: () => props.user,
      set: (value: User) => emit('update:user', value),
    })

    const router = useRouter()
    const toaster = useToaster()

    const isAnyLoading = computed(() => isLoading(data.accessMetadata) || isLoading(data.accessTemplates))
    const isAnyErrored = computed(() => isErrored(data.accessMetadata) || isErrored(data.accessTemplates))

    const selectedAccessTemplate = computed({
      get() {
        return localUser.value.accessTemplateName || CUSTOM_ACCESS_OPTION
      },
      set(newTemplateName) {
        if (isLoaded(data.accessTemplates)) {
          const newTemplate = data.accessTemplates.data.find((v) => v.name === newTemplateName)

          if (newTemplate) {
            localUser.value.accessTemplateID = newTemplate._id
            localUser.value.accessTemplateName = newTemplate.name
            localUser.value.access = [...newTemplate.access]
          } else {
            localUser.value.accessTemplateID = undefined
            localUser.value.accessTemplateName = CUSTOM_ACCESS_OPTION
          }
        }
      },
    })

    const accessTemplatesOptions = computed(() => {
      if (isLoaded(data.accessTemplates)) {
        return [CUSTOM_ACCESS_OPTION, ...data.accessTemplates.data.map((v) => v.name)]
      } else {
        return [CUSTOM_ACCESS_OPTION]
      }
    })

    watch(
      () => props.user,
      () => {
        window.scrollTo({ left: 0, top: 0 })
      }
    )

    const fetchAccessMetadata = async () => {
      try {
        data.accessMetadata = LOADING_STATE

        let accessMetadata = await getAccessMetadata()
        accessMetadata = extendAccessMetadataWithUserAccessData(accessMetadata, props.user.access)

        data.accessMetadata = loaded(accessMetadata)
      } catch (e) {
        data.accessMetadata = errored(e)
      }
    }

    const fetchAccessTemplates = async () => {
      try {
        data.accessTemplates = LOADING_STATE
        data.accessTemplates = loaded(await getAllAccessTemplates())
      } catch (e) {
        data.accessTemplates = errored(e)
      }
    }

    const actionHandler = async (actionFn: () => void) => {
      if (data.formState === FORM_LOADING_STATE) {
        return
      }

      window.scrollTo({ left: 0, top: 0 })

      data.formState = FORM_LOADING_STATE

      return actionFn()
    }

    const saveUserHandler = async () => {
      try {
        const userValidation = validateUser(localUser.value)

        if (userValidation !== true) {
          data.formState = formError('The form contains errors:', userValidation)
          return
        }

        localUser.value = await updateUser(localUser.value)
        data.formState = formSuccess(`User "${localUser.value.accessKey}" has been successfully updated.`)
      } catch (e) {
        data.formState = formError(
          `User "${localUser.value.name || localUser.value.accessKey}" could not be updated. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const deleteUserHandler = async () => {
      try {
        await deleteUser(localUser.value)

        const message = `User "${localUser.value.accessKey}" has been successfully deleted.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({ name: TechdemoRoute.Users })
      } catch (e) {
        data.formState = formError(
          `User "${localUser.value.name || localUser.value.accessKey}" could not be deleted. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const createUserHandler = async () => {
      try {
        const userValidation = validateUser(localUser.value)

        if (userValidation !== true) {
          data.formState = formError('The form contains errors:', userValidation)
          return
        }

        const user = await createUser(localUser.value)

        const message = `User "${localUser.value.accessKey}" has been successfully created.`
        data.formState = formSuccess(message)
        toaster && toaster.success(message)

        await router.push({
          name: TechdemoRoute.EditUser,
          params: { id: user._id },
        })
      } catch (e) {
        data.formState = formError(
          `User "${localUser.value.name || localUser.value.accessKey}" could not be created. ${e}`,
          (e as ApiError)?.errors
        )
      }
    }

    const cloneUser = () => {
      router.push({ name: TechdemoRoute.CreateUser, params: { id: localUser.value._id } })
    }

    fetchAccessMetadata()
    fetchAccessTemplates()

    return {
      data,
      localUser,
      actionHandler,
      saveUserHandler,
      deleteUserHandler,
      createUserHandler,
      cloneUser,
      isLoaded,
      isAnyLoading,
      isAnyErrored,
      accessTemplatesOptions,
      selectedAccessTemplate,
      isFormLoading,
      isFormErrored,
      isFormSuccess,
      toaster,
    }
  },
})
