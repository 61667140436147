import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataLoader = _resolveComponent("DataLoader")!
  const _component_DataLoadingError = _resolveComponent("DataLoadingError")!
  const _component_UserEditor = _resolveComponent("UserEditor")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DataLoader, {
        "is-loading": _ctx.isLoading(_ctx.data.user)
      }, null, 8, ["is-loading"]),
      _createVNode(_component_DataLoadingError, {
        subject: _ctx.data.user
      }, null, 8, ["subject"]),
      (_ctx.isLoaded(_ctx.data.user))
        ? (_openBlock(), _createBlock(_component_UserEditor, {
            key: 0,
            user: _ctx.data.user.data,
            "onUpdate:user": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.user.data) = $event)),
            "is-new-user": _ctx.data.isNewUser
          }, null, 8, ["user", "is-new-user"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}