import { getAuthHeaders, simpleAuthorizedQuery } from '@/utils/fetch'
import { User } from '@/modules/techdemo/model/users.model'
import axios from 'axios'
import { apiErrorCatcher } from '@/api/_shared'
import { techdemoApiUrl } from '@/modules/techdemo/api/_shared'

export async function getUser(id: string): Promise<User> {
  const user = await simpleAuthorizedQuery<User>(techdemoApiUrl(`admin/users/${id}`))

  return new User(user)
}

export async function getAllUsers(): Promise<User[]> {
  const users = await simpleAuthorizedQuery<User[]>(techdemoApiUrl('admin/users'))

  return users.map((user) => new User(user))
}

export async function updateUser(user: User): Promise<User> {
  const patch: Partial<User> = {
    _rev: user._rev,
    name: user.name,
    accessKey: user.accessKey,
    ethWallet: user.ethWallet,
    solWallet: user.solWallet,
  }

  if (user.accessTemplateID) {
    patch.accessTemplateID = user.accessTemplateID
  } else {
    patch.access = user.access
  }

  return axios
    .patch(techdemoApiUrl(`admin/users/${user._id}`), patch, {
      headers: getAuthHeaders(),
    })
    .catch(apiErrorCatcher)
    .then((r) => r.data)
    .then((data) => new User(data))
}

export async function deleteUser(user: User): Promise<unknown> {
  return axios
    .delete(techdemoApiUrl(`admin/users/${user._id}?rev=${user._rev}`), {
      headers: getAuthHeaders(),
    })
    .catch(apiErrorCatcher)
}

export async function createUser(user: User): Promise<User> {
  delete user._id
  delete user._rev

  return axios
    .post(techdemoApiUrl('admin/users'), user, {
      headers: getAuthHeaders(),
    })
    .then((r) => r.data)
    .then((data) => new User(data))
    .catch(apiErrorCatcher)
}
