import { AccessList, AccessDemo, validateAccess } from '@/modules/techdemo/model/access.model'
import { isValidEthAddress } from '@/utils/ethereum'
import { isValidSolAddress } from '@/utils/solana'

export class User {
  _id?: string
  _rev?: string
  name: string
  accessKey: string
  ethWallet: string | null
  solWallet: string | null
  availableDemos: AccessDemo[]
  access: AccessList
  accessTemplateID?: string
  accessTemplateName?: string

  constructor(doc: Partial<User>) {
    this._id = doc._id
    this._rev = doc._rev
    this.name = doc.name || ''
    this.accessKey = doc.accessKey || ''
    this.ethWallet = doc.ethWallet || null
    this.solWallet = doc.solWallet || null
    this.access = doc.access || []
    this.availableDemos = this.access.map((item) => item.demo)
    this.accessTemplateID = doc.accessTemplateID
    this.accessTemplateName = doc.accessTemplateName
  }
}

export function validateUser(user: User): true | string[] {
  let errors: string[] = []

  if (!user.accessKey && !user.ethWallet && !user.solWallet) {
    errors.push('At least one form of authentication is required.')
  }

  if (user.ethWallet && !isValidEthAddress(user.ethWallet)) {
    errors.push('Ethereum wallet address is invalid.')
  }

  if (user.solWallet && !isValidSolAddress(user.solWallet)) {
    errors.push('Solana wallet address is invalid.')
  }

  if (!user.accessTemplateID) {
    const accessValidation = validateAccess(user.access)

    if (accessValidation !== true) {
      errors = [...errors, ...accessValidation]
    }
  }

  return errors.length > 0 ? errors : true
}
