
import { computed, defineComponent, reactive, watch } from 'vue'
import { getUser } from '@/modules/techdemo/api/users.api'
import { User } from '@/modules/techdemo/model/users.model'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { RouteRecordName, useRoute } from 'vue-router'
import UserEditor from '@/modules/techdemo/components/UserEditor.vue'
import Panel from '@/components/UI/Panel.vue'
import { TechdemoRoute } from '@/modules/techdemo/routes'

export default defineComponent({
  name: 'User',
  components: { Panel, UserEditor, DataLoadingError, DataLoader },
  setup() {
    const data = reactive({
      isNewUser: false,
      user: LOADING_STATE as LoadingState<User>,
    })

    const route = useRoute()

    const title = computed(() => {
      if (route.name === TechdemoRoute.CreateUser) {
        return 'Create new user'
      } else if (isLoaded(data.user)) {
        return 'User: ' + data.user.data.name || data.user.data.accessKey
      } else {
        return 'User details'
      }
    })

    const getClonedUser = async (id: string): Promise<User> => {
      const user = await getUser(id)

      return {
        ...user,
        _id: undefined,
        _rev: undefined,
        accessKey: '',
        ethWallet: null,
        solWallet: null,
      }
    }

    const loadUser = async (id: string | string[]) => {
      try {
        data.user = LOADING_STATE

        if (route.name === TechdemoRoute.CreateUser) {
          data.user = loaded(id ? await getClonedUser(String(id)) : new User({}))
          data.isNewUser = true
        } else {
          data.user = loaded(await getUser(String(id)))
          data.isNewUser = false
        }
      } catch (e) {
        data.user = errored(e)
      }
    }

    type WatchT = [string | string[], RouteRecordName | null | undefined]
    watch(
      (): WatchT => [route.params.id, route.name],
      ([id, name]: WatchT) => {
        if (name === TechdemoRoute.CreateUser || name === TechdemoRoute.EditUser) {
          loadUser(id)
        }
      }
    )

    loadUser(route.params.id)

    return {
      data,
      title,
      isLoaded,
      isLoading,
      isErrored,
    }
  },
})
