
import { defineComponent, PropType } from 'vue'
import { User } from '@/modules/techdemo/model/users.model'
import { useRouter } from 'vue-router'
import { TechdemoRoute } from '@/modules/techdemo/routes'

export default defineComponent({
  name: 'UsersDetailsTable',
  props: {
    users: {
      type: Array as PropType<User[]>,
      required: true,
    },
  },
  setup() {
    const router = useRouter()

    const navigateToUser = (id: string) => {
      router.push({ name: TechdemoRoute.EditUser, params: { id } })
    }

    return { navigateToUser }
  },
})
