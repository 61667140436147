import * as SolWeb3 from '@solana/web3.js'

export function isValidSolAddress(address: string): boolean {
  try {
    const publicKey = new SolWeb3.PublicKey(address)

    return SolWeb3.PublicKey.isOnCurve(publicKey.toBytes())
  } catch (e) {
    return false
  }
}
