
import { defineComponent, onMounted, reactive } from 'vue'
import { getAllUsers } from '@/modules/techdemo/api/users.api'
import { User } from '@/modules/techdemo/model/users.model'
import { errored, isLoaded, loaded, LOADING_STATE, LoadingState, isErrored, isLoading } from '@/utils/loading-state'
import UsersDetailsTable from '@/modules/techdemo/components/UsersDetailsTable.vue'
import DataLoader from '@/components/UI/DataLoader.vue'
import DataLoadingError from '@/components/UI/DataLoadingError.vue'
import { useRouter } from 'vue-router'
import Panel from '@/components/UI/Panel.vue'
import { TechdemoRoute } from '@/modules/techdemo/routes'

export default defineComponent({
  name: 'Users',
  components: { Panel, DataLoadingError, DataLoader, UsersDetailsTable },
  setup() {
    const data = reactive({
      users: LOADING_STATE as LoadingState<User[]>,
    })

    const router = useRouter()

    const goToUserCreator = () => {
      router.push({ name: TechdemoRoute.CreateUser })
    }

    onMounted(async () => {
      try {
        data.users = loaded(await getAllUsers())
      } catch (e) {
        data.users = errored(e)
      }
    })

    return {
      data,
      goToUserCreator,
      isLoaded,
      isLoading,
      isErrored,
    }
  },
})
